import { Container } from "@mui/material";
import React from "react";
import Markdown from "react-markdown";

const BrickRichText = ({ text }) => {
  // manage bold trouble:
  const newText = text
    .replace(/<br>/g, "\n\r &nbsp;")
    .replace(/<\/br>/g, "\n\r &nbsp;")
    .replace(/\(0\)/g, "&#x2070;")
    .replace(/\(1\)/g, "&#xB9;")
    .replace(/\(2\)/g, "&#xB2;")
    .replace(/\(3\)/g, "&#xB3;")
    .replace(/\(4\)/g, "&#x2074;")
    .replace(/\(5\)/g, "&#x2075;")
    .replace(/\(6\)/g, "&#x2076;")
    .replace(/\(7\)/g, "&#x2077;")
    .replace(/\(8\)/g, "&#x2078;")
    .replace(/\(9\)/g, "&#x2079;");

  return (
    <Container
      maxWidth="false"
      sx={{ px: 0, py: 0, textAlign: "justify", overflow: "hidden" }}
      style={{ padding: 0 }}
    >
      <Markdown children={newText} skipHtml={true} className="RichText" />
    </Container>
  );
};

export default BrickRichText;
